@font-face {
    font-family: 'Minecraft Font';
    font-style: normal;
    font-weight: 400;
    src: url('Fonts/minecraftfont.ttf'); /* IE9 Compat Modes */
    src: local('Minecraft Font'), local('MinecraftFont'),
         url('Fonts/minecraftfont.ttf') format('truetype'), /* Modern Browsers */
  }

html body {
    font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.content-select-center{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.content-select-dropdown{
    flex:1;
    margin-bottom: 5px;
}
.content-select-create{
    flex: 0 0 155px; 
    margin-left: 20px;
}
.btn .caret {
    margin-left: 5px !important;
}
.btn-group, .btn-group-vertical{
    width:100%;
}
#dropdown-select, .dropdown-menu{
    width:100%;
    text-align: center !important;
    vertical-align: center;
}

.dropdown-select-custom-span{
    vertical-align: middle;
    font-size: 12px !important;
}

.dropdown-select-mi{
    min-height: 59px;
    line-height: 59px;
}
.dropdown-select-mi.disabled{
    background-color: #6cca8f;
}

.dropdown-select-mi.disabled > a > span{
    color:lightgray;
}
.dropdown-select-mi > a{
    text-align: center;
    font-size: 1.4em;
    height: 59px;
    line-height: 59px;
    vertical-align: middle;
}

.dropdown-select-mi > a > span{
    display:inline;
    display:inline-table;
    display:inline-block;
    vertical-align:middle;
    line-height: 20px;
}
.info-square{
    width:15px;
    display:block;
    margin:auto;
    padding-bottom: 5px;
}

.tag-button{
    margin:10px;
}

.tag-button-active{
    margin:10px;
    background-color: #6cca8f !important;
}

.tag-span{
    margin:0px 0px 0px 0px;
    padding: 0px 0px 0px 0px !important;
    background: #F6F5F5;
    outline: 1px solid lightgrey;
    padding: .2em .4em;
    color: black !important;
    text-decoration: none !important;
}
.tag-span:hover{
    text-decoration: none !important;
    text-decoration-color: white !important;
}

.btn-margin {
    margin: 7px 3px;
}

.btn-primary {
    margin-top: 75px;
}

.btn {
    border: 1px solid !important;
    border-color: #0e0f0e !important;
    border-radius: 6px !important;
}

body .form-group {
    clear: both;
}

.details .form-group {
    margin-bottom: 0;
}

.form-group>*:not(.FontAwesomeIcon),
.radio-inline {
    color: #333 !important;
}

.form-group:not(.meta-data-radio) .form-label {
    font-size: 18px;
}

.form-group.meta-data-radio .form-label {
    font-size: 16px;
}

.form-group label:not(.radio-inline) {
    display: inline-block;
}

.form-group .form-text {
    display: inline;
    font-weight: normal;
    color: #333 !important;
}

/* .navbar-default{
    background-color:#cf7210 !important;
} */

.form-group .form-text,
.form-group .btn,
.form-group .badge,
.form-group .control-label,
.form-group .help-block,
.form-group .form-check,
.form-group .radio,
.form-group .radio-inline,
.popover-content,
.form-group .message {
    font-size: 13px;
    margin-bottom: 0px !important;
}

/* Inline treatments for the text in upload form component */

.file-upload .message {
    margin: 0;
    line-height: normal;
}

/* Layout for upload form component */

.form-group .message .btn {
    display: inline;
    line-height: normal;
    padding-bottom: 0;
    padding-top: 0;
}

.file-upload input[type=file] {
    margin-top: 1em;
}

input[type='file'] {
    color: rgba(0, 0, 0, 0)
}

/* Read More treatment */

.form-group .btn-link,
.form-group a {
    text-decoration: underline !important;
    outline: 0 !important;
}

/* Popover links readable on dark background */

.popover-content a {
    color: #1aa6d4 !important;
    text-decoration: underline !important;
}

/* Widen popover */

body .popover {
    max-width: 320px;
}

/* Squish content in popover */

.popover-content ul {
    padding-left: 20px;
    -webkit-padding-start: 20px;
    -moz-padding-start: 20px;
}

.details .has-feedback.has-success .form-control,
.details .has-feedback.has-error .form-control {
    -webkit-appearance: none;
}

.details .has-feedback .form-control[disabled] {
    -webkit-appearance: none;
}

.has-feedback label~.form-control-feedback {
    position: static;
    padding: 5px 2px;
    width: initial;
    height: unset;
    display: block;
    float: right;
    line-height: 1;
}

body h1,
body .h1 {
    font-size: 32px;
    font-weight: 400;
    letter-spacing: -.25px;
    color: #333;
}

body table {
    width: 100%;
}

.details td,
.details th {
    vertical-align: top;
    padding: 5px 0 !important;
    font-size: 14px;
}

.details td {
    width: 75%;
}

.details aside th {
    padding-left: 0 !important;
    white-space: unset;
    font-size: 13px;
    vertical-align: middle !important;
}

.details aside td {
    width: 60%;
}

.details h4 {
    color: #333;
    font-weight: 500;
    text-align: center;
    margin: 0 auto 15px;
}

.details th {
    white-space: pre;
}

.details .value {
    white-space: pre-line;
}

.has-feedback.file-upload .form-control,
.meta-data-radio {
    display: table;
    outline-color: #333;
}

body a:hover {
    cursor: default;
}

body .card {
    box-shadow: rgba(0, 0, 0, 0.57) 0px 0px 15px -3px;
    padding: 9px;
    margin-bottom: 10px;
}

body main,
body aside {
    padding-top: 5px;
    margin-top: 5px;
}

.has-error input[type="file"]:focus {
    outline-color: #f04124 !important;
}

.has-success input[type="file"]:focus {
    outline-color: #43ac6a !important;
}

.key-art img {
    max-width: 350px;
}

a:hover{
    cursor: pointer !important;
}


/* RESPONSIVENESS for Main/Aside & Key Art */

@media screen and (max-width: 974px) {
    .key-art img {
        display: block;
        max-width: 100%;
        max-height: 300px;
    }
    body aside {
        margin-top: 0;
    }
}

.status-bar {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    margin: 40px auto 25px;
}

.status-bar hr {
    height: 1px;
    background-color: #999;
    border: 0;
    width: calc(80% - 20px);
    margin: 0 auto;
    padding-right: 2%;
}

.status-bar ul {
    display: flex;
    text-align: center;
    align-content: stretch;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    margin: -.8em 0 0;
    padding: 0;
}

.status-bar li {
    margin: 0;
    padding: 0;
    color: #666;
    width: 16.666%;
    position: relative;
    flex-grow: 0;
    flex-shrink: 1;
    align-self: flex-start;
}

.status-bar .icon-wrapper {
    background-color: #fafafa;
    display: inline-block;
    padding: 0 5px;
}

.status-bar .icon {
    font-size: 1.3em;
}

.status-bar .has-danger .icon-wrapper .icon:not(.fa-circle) {
    color: #f04124;
}

.status-bar .has-warning .icon-wrapper .icon:not(.fa-circle) {
    color: #b67102;
}

.status-bar .has-success .icon-wrapper .icon:not(.fa-circle) {
    color: #107c10;
}


.status-label {
    display: block;
    font-size: 12px;
    color: #333;
    word-break: break-word;
    margin: 0 auto;
    max-width: 100%;
}


.offerList table td {
    font-size: 14px;
    line-height: 1.2;
}

.col-lg-12 {
    max-width: unset !important;
}

.col-lg-12 .card>* {
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    width: 100%;
}

.offerList table th {
    font-size: 14px;
    line-height: 1.4;
    padding: 8px !important;
}

.offerList {
    margin-top: 20px;
}

.offerList td,
.offerList th {
    vertical-align: middle !important;
}

/* @media screen and (min-width: 767px) {
    .navbar-nav:not(.navbar-right)>div:first-child {
        margin-left: -15px;
    }
} */

@media screen and (max-width: 450px) {
    .offerList th:nth-child(2),
    .offerList td:nth-child(2) {
        display: none;
    }
    /* .navbar-toggle {
        margin-right: 0 !important;
    } */
}

@media screen and (min-width: 1200px) {
    .col-lg-12 {
        max-width: 1000px;
        margin-left: auto !important;
        margin-right: auto !important;
        float: none !important;
    }
    /* .navbar .container {
        max-width: 1000px;
        margin-left: auto !important;
        margin-right: auto !important;
        float: none !important;
    } */
}

.test-report [aria-expanded='false'] .FontAwesomeIcon:before {
    content: "\e080";
    margin-right: 15px;
}

.test-report [aria-expanded='true'] .FontAwesomeIcon:before {
    content: "\e114";
    margin-right: 15px;
}

.client-version th {
    white-space: normal;
}

.details .comment {
    margin: .5em 0;
}

.message {
    font-size: 13px;
    font-weight: 600;
}

body h1, body .h1 {
    font-family: 'Noto Sans', sans-serif !important;
    text-transform: uppercase;
    font-weight: 700;
}

h4 {
    font-family: 'Noto Sans', sans-serif !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
}

.details table {
    margin-bottom: 0 !important;
}

.details main  table+table,
.details aside button{
    margin-top: 20px;
}

*{
    /*font-family: 'Noto Sans', sans-serif;*/
}

.navbar-default .navbar-nav>div>a:hover, .navbar-default .navbar-nav>div>a:focus,
.topnavbar
{
    color: white !important;
    background-color: rgb(31,31,31) !important;
    text-transform: uppercase !important;
    background-image: url("./Images/banner-bg.jpg");
    background-attachment:fixed;
}

.nav-link {
    color: white !important;
}

.form-group:not(.meta-data-radio) .control-label{
    text-transform: uppercase !important;
    font-family: 'Noto Sans', sans-serif;
}

#statussplash {
    color: #fffb18;
    font-size: 1em;
    /* font-family:'Minecraft Font'; */
    text-shadow:2px 2px rgb(63, 63, 0);
    white-space: nowrap;
    margin:auto;
    text-align: center;
}

.pulse {
    animation: glow 0.8s infinite alternate;
}

@keyframes glow {
    to {
        text-shadow: 0 0 20px #fffb18;
    }
}

.pop {
    animation: pop 0.58s infinite;
    -webkit-animation: pop 0.58s infinite;
}
@keyframes pop {
    from {
        transform:scale(0.95)
    }
    50% {
        transform:scale(1)
    }
    to {
        transform:scale(0.95)
    }
}
@-webkit-keyframes pop {
    from {
        -webkit-transform:scale(0.95)
    }
    50% {
        -webkit-transform:scale(1)
    }
    to {
        -webkit-transform:scale(0.95)
    }
}
.tilt {
    max-width: 400px;
    -ms-transform: translateX(200px) translateY(-50px) rotate(-25deg);
    -webkit-transform: translateX(200px) translateY(-50px) rotate(-25deg);
    transform: translateX(200px) translateY(-50px) rotate(-25deg) ;
}

.splash{
    display:block;
    margin: auto;
}

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}

button:focus {
    outline:0 !important;
}

.navbar-default .navbar-nav>div>a:hover, .navbar-default .navbar-nav>div>a:focus{
    color:#43ac6a !important;

}

.ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer{
    display:flex !important;
    flex-direction: column;
    justify-content: center;
    outline: 0;
    -webkit-transition: 00.40s;
    transition: 00.40s
}

.ReactTable .rt-thead .rt-resizable-header:last-child{
    outline:0;
    -webkit-transition: 00.40s;
    transition: 00.40s;
}

.navbar-default .navbar-nav>div>a:hover, .navbar-default .navbar-nav>div>a{
    text-transform: uppercase !important;
    cursor: pointer;
    font-family: 'Noto Sans', sans-serif !important;
    font-size: 1em !important;
}

table a {
    cursor: pointer !important;
}
.table-striped>tbody>tr:nth-of-type(even),
.table-striped>thead>tr:nth-of-type(odd)
 {
    background-color: white;
}

.youtube > .FontAwesomeIcon-warning-sign:before{
    content:"\e063";
}

.reportcolumn{
    border-top:none !important;
}

.reportcolumn.left{
    font-weight: normal;
}

.reportcolumn.middle{
    text-align: center;
}

.reportcolumn.right{
    text-align: right;
}

.validationresult{
    float: left;
    text-align: center;
}

.validationroot {
    display: flex;
    align-items: center;
    justify-content: center;
}
.validationroot:after {
    content: "";
    display: table;
    clear: both;
}
.validationresult.symbol{
    width:10%;
}

.validationresult.decision{
    width:15%;
}

.DayPicker {
    font-size: 1.5rem !important;
}

.validationresult.filename{
    width:55%;
}

.validationresult.button{
    width:20%;
    text-decoration: none !important;

}

#validationformfield{
    padding-right: 12px !important;
}

#nounderline{
    text-decoration: none !important;
    margin:auto;
}

div#validationprogress.validationProgress.progress {
    padding: 0px !important;
    margin-bottom: 0px !important;
}
.validatorsubmit[disabled] {
    background-color: gray !important;
    border-color: darkgrey !important;
}

.details .form-group select.form-control{
    padding-left: 3px;
    font-size: 14px;
}

.action {
    color:red;
}

.bold {
    font-weight: bold;
}

.validationparent{
    float:left;
    width:100%;
    overflow: hidden;
    height: auto;
}

.validationparent .left{
    float:left;
    margin-right: 10px;
}

.validationparent .right{
    float:left;
    width:95%;
}

.leftHeading {
    text-align: left;
}
.notSortableColumnHeading {
    margin-top: 4px;
}

.btn-optin {
    color: #fff;
    background-color: #712687;
    border-color: #9409b5;
    text-transform: uppercase;
}

.btn-optin.focus, .btn-optin:focus {
    color: #fff;
    background-color: #602073;
}

.btn-optin:hover:not(:disabled) {
    color: #fff;
    background-color: #602073;
}

.btn-optin.active, .btn-optin:active, .open > .dropdown-toggle.btn-optin {
    color: #fff;
    background-color: #602073;
}

.btn-optin.active.focus, .btn-optin.active:focus, .btn-optin.active:hover, .btn-optin:active.focus, .btn-optin:active:focus, .btn-optin:active:hover, .open > .dropdown-toggle.btn-optin.focus, .open > .dropdown-toggle.btn-optin:focus, .open > .dropdown-toggle.btn-optin:hover {
    color: #fff;
    background-color: #4f1b5e;
}

.btn-optin.active, .btn-optin:active, .open > .dropdown-toggle.btn-optin {
    color: #fff;
    background-image: none;
}

.btn-optin.disabled.focus, .btn-optin.disabled:focus, .btn-optin.disabled:hover, .btn-optin[disabled].focus, .btn-optin[disabled]:focus, .btn-optin[disabled]:hover, fieldset[disabled] .btn-optin.focus, fieldset[disabled] .btn-optin:focus, fieldset[disabled] .btn-optin:hover {
    color: #fff;
}

.btn-optin .badge {
    color: #fff;
}

.nodata {
    text-align: center;
    background-color: #e7e7e7;
    padding: 5em;
}
.noeligible {
    padding:10em 5em;
}

.switchChecked {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 23px;
    background-color: #63107C !important;
    color: white;
    vertical-align: top;
    cursor: pointer;
    border-radius: 6px;
}

.switchUnchecked {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 23px;
    background-color: #B087BD !important;
    color: white;
    vertical-align: top;
    cursor: pointer;
    border-radius: 6px;
}

.switchDisabled {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 23px;
    background-color: #d6d6d6 !important;
    color: white;
    vertical-align: top;
    cursor: no-drop;
    border-radius: 6px;
}

.switch input {
  opacity: 0.00;
  width: 0.00;
  height: 0.00}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0.00;
    left: 0.00;
    right: 0.00;
    bottom: 0.00;
    -webkit-transition: 00.40s;
    transition: 00.40s}

.slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 00.40s;
    transition: 00.40s;
    z-index: 1;
    border-radius: 4px;
    padding-left: 3px}
.slider:after {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 6px;
    bottom: 4px;
    background-image: url("./Images/toggleOn.png");
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-transition: 00.40s;
    transition: 00.40s}
.switch:after {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
    right: 9px;
    bottom: 6.5px;
    background-image: url("./Images/times-solid-purple.png");
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-transition: 00.40s;
    transition: 00.40s}

.selectAll .slider:after {
    background-image: url("./Images/check-double-solid-white.png");
}

input:focus + .slider {
    box-shadow: 0.00px 0.00px 1px #2196f3;
}

input:disabled + span.slider:after {
    background-image: url("./Images/check-solid-purple.png");
}

input:disabled + span.slider {
    cursor: no-drop;
}

input:checked + .slider:before {
  -webkit-transform: translateX(35px);
  -ms-transform: translateX(35px);
  transform: translateX(27px);
}

/* END: Toggle css classes */
/**
 * <div class="react-tags">
 *   <div class="react-tags__selected">
 *     <button class="react-tags__selected-tag">
 *       <span class="react-tags__selected-tag-name" />
 *     </button>
 *   </div>
 *   <div class="react-tags__search">
 *     <div class="react-tags__search-wrapper">
 *       <input class="react-tags__search-input" />
 *       <div />
 *     </div>
 *     <div class="react-tags__suggestions">
 *       <ul>
 *         <li class="is-active">
 *           <mark />
 *         </li>
 *         <li class="is-disabled">
 *           <mark />
 *         </li>
 *       </ul>
 *     </div>
 *   </div>
 */
 .react-tags {
    position: relative;
    padding: 1px 0 0 1px;
  
    /* clicking anywhere will focus the input */
    cursor: text;
  }
  
  
  .react-tags__selected {
    display: inline;
  }
  
  .react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 2px 0;
    padding: 6px 8px;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    background: #F1F1F1;
  
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }
  
  .react-tags__selected-tag:after {
    content: '\2715';
    color: #AAA;
    margin-left: 8px;
  }
  
  
  .react-tags__search {
    display: inline-block;
  
    /* match tag layout */
    padding: 2px 2px;
    margin-bottom: 0px;
  
    /* prevent autoresize overflowing the container */
    max-width: 100%;
  }
  
  @media screen and (min-width: 30em) {
  
    .react-tags__search {
      /* this will become the offsetParent for suggestions */
      position: relative;
    }
  
  }
  
  .react-tags__search-input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;
  
    /* remove styles and layout from this element */
    margin-left: -10px;
    padding: 0;
    border: 0;
    outline: none;
    text-indent: 10px;
  
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }
  
  .react-tags__search-input::-ms-clear {
    display: none;
  }
  
  .react-tags__suggestions {
    position: absolute;
    z-index:100;
    top: 100%;
    left: 0;
    width: 100%;
  }
  
  @media screen and (min-width: 30em) {
  
    .react-tags__suggestions {
      width: 240px;
    }
  
  }
  
  .react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  
  .react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
  }
  
  .react-tags__suggestions li mark {
    text-decoration: none;
    background: none;
    font-weight: 600;
    padding: 0em;
    margin:none;
  }

  
  .react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
  }
  
  .react-tags__suggestions li.is-active {
    background: #b7cfe0;
  }
  .personaOfferPrice > h3 > div > img{
      float: left;
      max-height: 25px;
      margin-right: 5px;
      margin-top:2px;
  }
  .personaOfferPrice > h3 {
    float: left;
    margin-bottom: 25px;
}
#OfferPrice > div {
    float:left;
}
#OfferPrice > div > img {
    float: right;
    max-width: 20px;
    margin-left: 5px;
}

label.btn {
    border-radius: 0 !important;
}

.checkbox > badge  {
    color: black;
  }

  .checkbox > badge > input{
    margin-top : 3px;
  }

  .refresh-card-container {
    display: flex;
    flex-direction: column;
  }
  
  .refresh-table-container {
    flex-grow: 1;
  }
  
  .refresh-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
  }

  .list-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .modal-button {
    margin: 10px; /* Add some margin for spacing */
  }

  .table-container {
    width: 100%
  }

  .tags-container {
    display: flex;
    flex-wrap: wrap;
  }

  .tag-badge.pill {
    border-radius: 50px;
    padding-top: 4px;
    margin-inline: 3px;
    margin-right: 10px;
    font-size: 12px;
  }

  .tag-badge .icon {
    margin-left: 8px; /* Add space between text and icon */
    cursor: pointer;
  }

  .modal-70w {
    max-width: 70% !important;
  }

  .custom-file-button label:hover {
    background-color: #dde0e3;
    cursor: pointer;
  }
  